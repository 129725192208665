<template>
    <div class="certificate">
        <Blank v-if="isEmpty" :title="emptyText" tip="zs"></Blank>
        <div class="main" v-if="cerList.length>0">
            <div class="list"
                v-for="(item,index) in cerListParadigm"
                :key="index"
            >
                <div class="top">
                    <div class="lf">
                        <div class="title">
                            <!-- <img src="/static/images/certificate_title.png" alt=""> -->
                            <span>{{item.rank}}</span>
                        </div>
                        <div class="text">证书专业：{{item.profession}}</div>
                        <div class="text">证书属性：{{item.attribute}}</div>
                        <!-- <div class="text">证书有效期：2020-01-01-2022-01-01</div> -->
                    </div>
                    <div class="lr">
<!--                        <img src="/static/images/SignAcontract.png" alt="">-->
                        <img :src="require('@/assets/yq/certificate-status-'+item.status+'.png')" >
                    </div>
                </div>
                <div class="button">
                    <div class="text">专属顾问：{{item.user_info.realname}}</div>
                    <div class="btn">   
                        <a :href="item.user_info.phone?'tel:'+ item.user_info.phone : 'tel:'+config.telePhone">
                            {{item.consulte}}
                        </a>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Blank from "@/components/blank.vue";
import {getCertificateList} from "@/api/user";
import config from "@/config/config";
export default {
    components: { Blank },
    data() {
        return {
            title:"我的证书",
            backVisible: true,
            cerList:[],
            value1: '',
            emptyText:"暂无证书可以管理哦!",
            isEmpty:false,
            callPhone: ""
        }
    },
    methods: {
        callTelephone(obj){
            let p;
            if(obj.phone){
                p = obj.phone;
            }else{
                p = config.telePhone;
            }
            // wx.makePhoneCall({
            //     phoneNumber: p,
            // })
            this.callPhone = 'tel:' + p;
        }
    },
    computed: {
        cerListParadigm() {
            if(this.cerList.length >0){
                this.cerList.forEach((val)=>{
                    val.consulte = "联系客服";
                    // val.consulte = val.status===0? "续约咨询" : "合同咨询";
                });
            }
            return this.cerList;
        },
    },
    created(){
        getCertificateList().then(res=>{
            let {status,statusMessage,statusCode} = res;
            if(status && statusCode===0){
                if(res.data.length == 0){
                    this.isEmpty = true;
                }
                this.cerList = res.data;
            }else{
                this.$toast({
                    message: statusMessage
                })
                setTimeout(() => {
                    this.$router.go(-1)
                }, 2500);
            }
            
        })
    }
};
</script>

<style scoped>
    div{
        box-sizing: border-box;
    }
    .certificate{
        padding-top: 50px;
    }
    .certificate .main {
        padding: 20px;
		/* position: relative; */
    }
    .list {
        background-color: #ffffff;
        margin-bottom: 20px;
        padding: 20px 15px 0 15px;
        border-radius: 6px;
    }
    .text {
        color: #999999;
        font-size: 13px;
        margin-bottom: 5px;
    }
    .title {
        font-size: 18px;
        color: #222222;
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 14px;
    }
    .list .top .lr img {
        width: 60px;
        height: 60px;
    }
    .list .top .lf img {
        height: 30px;
        width: 26px;
        margin-right: 13px;
    }
    .list .top {
        display: flex;
		justify-content: space-between;
    }
    .button {
        margin-top: 15px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #EDF1F4;
    }
    .button .btn{
        width: 76px;
        height: 28px;
		line-height: 28px;
		border-radius: 14px;
		font-weight: 400;
		text-align: center;
		font-size: 13px;
		background: linear-gradient(-90deg, #4B62BD, #5267BF);
	}
    .button .btn a{
        color: #fff;
    }
	.button .btn:active{
		opacity: 0.8;
	}

</style>
